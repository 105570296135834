@import "../../styles.scss";

.footer {
  padding: 76px 0 0;
  background-color: #FFFFFF;
}

.footer_data {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 129px;
}
.footer_links {
  margin-bottom: 24px;
  display: flex;
}
.footer_link {
  text-decoration: none;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #2D2D2D;
  margin-left: 28px;
}

.footer_sublink {
  @extend .footer_link;
  font-size: 12px;
  margin-left: 14px;
}

.footer_sublink:first-child {
  margin-left: 0;
}

.footer_link:first-child {
  margin-left: 0;
}
.footer_contacts {
  margin-bottom: 16px;
  display: flex;
  align-items: flex-end;
}
.footer_email {
  margin-left: 9px;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.04em;
  text-decoration-line: underline;
  color: #2D2D2D;
}
.footer_description {
  max-width: 438px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #A3ABB1;
}

.withoutBg {
  background-color: transparent;
}

@media (max-width: 630px) {
  .footer_links {
    flex-direction: column;
  }
  .footer_link {
    margin: 0 0 16px;
  }
}
@media (max-width: 976px) {
  .footer {
    padding: 48px 0 0;
  }
  .footer_data {
    margin-bottom: 48px;
  }
}